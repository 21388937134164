import React from 'react'
import Image from 'gatsby-image'

import Layout from '@/components/layout'
import {
  CaseStudyHeader,
  DiagonalBox,
  Width,
  SEO,
  ResponsiveVideo,
} from '@/components'
import { Colors } from '@/styles'

import * as S from './styles'

const AboutAT = props => {
  const { data } = props.data.prismicAbout
  const { social_image } = props.data.prismicMeta.data

  return (
    <Layout locale={props.pageContext.lang}>
      <S.AboutComponent>
        <SEO
          title="About"
          description={
            data.hero_title?.text ||
            'Let’s turn great ideas into impactful products.'
          }
          image={social_image?.url}
        />
        <CaseStudyHeader isAboutATPage={true} />
        <S.Hero bg={Colors.lightBlue}>
          <Width>
            {data?.hero_title?.text && (
              <S.Title color={Colors.navy}>{data.hero_title.text}</S.Title>
            )}
            {data?.hero_description?.html && (
              <S.Text
                dangerouslySetInnerHTML={{ __html: data.hero_description.html }}
              />
            )}
          </Width>
          <Width>
            {data?.hero_image?.fluid && (
              <Image
                fluid={data.hero_image.fluid}
                alt={data.hero_image.alt || ''}
              />
            )}
            <S.CountInfo>
              {data?.count_items?.map((item, index) => (
                <div key={`count-${index}`}>
                  <S.CountNumber>{item.count_number?.text}</S.CountNumber>
                  <S.CountDescription>
                    {item.count_description?.text}
                  </S.CountDescription>
                </div>
              ))}
            </S.CountInfo>
          </Width>
        </S.Hero>
        <S.CasesSection>
          {data?.cases_title?.text && (
            <S.TitleH3 color={Colors.navy}>{data.cases_title.text}</S.TitleH3>
          )}
          {data?.cases_description?.html && (
            <S.Text
              dangerouslySetInnerHTML={{
                __html: data.cases_description.html,
              }}
            />
          )}
          <S.CasesWrapper>
            {data?.cases?.map((item, index) => (
              <S.CaseLink to={item.case_item_link} key={`case-${index}`}>
                <>
                  <S.TextCaseWrapper>
                    <S.CaseTitle color={Colors.navy}>
                      {item.case_item_title?.text}
                    </S.CaseTitle>
                    <S.CaseDescription color={Colors.navy}>
                      {item.case_item_description?.text}
                    </S.CaseDescription>
                  </S.TextCaseWrapper>
                  {item.case_item_image?.fluid && (
                    <S.CaseImage
                      fluid={item.case_item_image.fluid}
                      alt={data.case_item_image?.alt || ''}
                    />
                  )}
                </>
              </S.CaseLink>
            ))}
          </S.CasesWrapper>
          {data?.cases_cta_text && data?.cases_cta_url && (
            <S.StyledLink to={data.cases_cta_url}>
              {data.cases_cta_text}{' '}
              <S.StyledArrow
                color={Colors.navy}
                arrowProps={{ role: 'none' }}
              />
            </S.StyledLink>
          )}
        </S.CasesSection>
        <DiagonalBox bg={Colors.lightGray} color="unset">
          <S.DiagonalInner>
            {data?.distributed_title?.text && (
              <S.TitleH3 color={Colors.navy} align="right">
                {data.distributed_title.text}
              </S.TitleH3>
            )}
            {data?.distributed_description?.html && (
              <S.Text
                align="right"
                dangerouslySetInnerHTML={{
                  __html: data.distributed_description.html,
                }}
              />
            )}
            {data?.distributed_video_link?.url && (
              <S.ResponsiveVideoContainer>
                <ResponsiveVideo
                  url={data.distributed_video_link.url}
                  loop
                  muted
                  playing
                  playsinline
                />
              </S.ResponsiveVideoContainer>
            )}
          </S.DiagonalInner>
        </DiagonalBox>
        <Width>
          <S.SolveWithUsWrapper>
            {data?.with_us_title?.text && (
              <S.Title
                color={Colors.navy}
                align="center"
                css={{ maxWidth: '40rem' }}
              >
                {data.with_us_title.text}
              </S.Title>
            )}
            {data?.with_us_cta_text && data?.with_us_cta_url && (
              <S.StyledLink to={data.with_us_cta_url}>
                {data.with_us_cta_text}{' '}
                <S.StyledArrow
                  color={Colors.navy}
                  arrowProps={{ role: 'none' }}
                />
              </S.StyledLink>
            )}
          </S.SolveWithUsWrapper>
        </Width>
      </S.AboutComponent>
    </Layout>
  )
}

export default AboutAT
